@import url('https://fonts.googleapis.com/css?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap');
.sidebar {
  height: 100vh;
  width: 318px;
  background-color: #f2f3f7;
  text-align: center;
  padding-top: 10px;
}
.sidebar > h1 {
  padding-top: 10px;
  text-align: center;
}
.topHashtag {
  color: #000;
  font-weight: bold;
}

.h1_links {
  text-decoration: none;
  font-family: 'Aclonica', sans-serif;
  color: #000;
  font-size: 32px;
}
.h1_links:hover {
  color: #333;
}
.my-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: 10px;
}
.sidebar > p {
  margin-top: 10px;
  text-align: center;
}

.sidebar-nav {
  margin-left: -30px;
  margin-top: 30px;
  text-align: center;
}
.sidebar-nav-items {
  margin-bottom: 15px;
}
ul {
  list-style: none;
}

.links {
  background-image: linear-gradient(to right, #000, #000, #000);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 2px 0;
  position: relative;
  text-decoration: none;
  font-family: 'Comfortaa', cursive;
  font-size: larger;
  line-height: 1rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.links:before {
  content: '';
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 1),
    rgba(255, 0, 180, 1),
    rgba(0, 100, 200, 1)
  );
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.links:hover {
  background-position: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 1),
    rgba(255, 0, 180, 1),
    rgba(0, 100, 200, 1)
  );
}

.links:hover::before {
  width: 100%;
}

.icon-img {
  height: 28px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.icon-img:hover {
  scale: 1.2;
  cursor: pointer;
}
.heart {
  color: red;
}

/*//////////////media ////////////////////*/
@media (max-width: 980px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 29px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 28px;
  }
  .links {
    display: none;
  }

  .gmail {
    font-size: 16px;
    font-weight: normal;
  }
}

@media (max-width: 370px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 24px;
  }
  .links {
    font-size: 16px;
  }
}
