@import url('https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap');

.box {
  margin: 15px;
  margin-top: 40px;
}
.head {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(140, 128, 128);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 15px;
  font-weight: 900;
}

.heading {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  margin: 4rem 2vw;
  padding: 0;
  list-style-type: none;
}

.card {
  display: block;
  border-radius: 21px 21px 0px 0px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px;
}

.card__image {
  width: 100%;
  min-height: 300px;
}

.card__title__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.card__title {
  font-size: 1rem;
  margin: -2px 0 0.3em;
  color: #000;
  padding: 14px 0px 5px 0px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.card__title__img {
  height: 20px;
  cursor: pointer;
}

@media (max-width: 450px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
