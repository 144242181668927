@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  height: 710px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  font-family: 'Quicksand', Arial, sans-serif;
  line-height: 1.3em;
  letter-spacing: 0.04em;
}
.side {
  width: 300px;
}
.main {
  overflow: scroll;
  overflow-x: hidden;
}

p {
  color: rgba(0, 0, 0, 0.7);
  font-weight: normal;
}

/*//////////////// Media Query/////////////////*/

@media (max-width: 992px) {
  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-weight: bold;
  }
  p {
    font-weight: bolder;
  }
  .main {
    margin-top: -40px;
    overflow-y: hidden;
  }
}

@media (max-width: 769px) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .main {
    overflow: auto;
    overflow-x: hidden;
  }
}
